import { initialDetailedStoryState } from "./state";

export const detailedStory = (state = initialDetailedStoryState, action) => {
  switch (action.type) {
    case "SET_STORY_DETAIL":
      return {
        dataStory: action.data
      }
    case "UPDATE_STORY_DETAIL":
      return {
        dataStory: {
          ...state.dataStory,
          status: action.data
        }
      }
    default:
      return state;
  }
};
