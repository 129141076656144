// CleverTap
import * as EVENT from '../utils/eventKeys'
import { storage as LocalStorage } from "services/config/storage";

const logEvents = (event, payload) => {
    console.log(event,payload)
    if(LocalStorage.fetch.authToken()){
        //FREAD-1301
        //logProfile();
        //FREAD-1301
    }
    if (payload) {
        Object.keys(payload).forEach(key => payload[key] === undefined && delete payload[key])
        window.clevertap.event.push(event, payload);
    } else {
        window.clevertap.event.push(event);
    }
};

const logProfile=()=>{
    let data ={}
    data[EVENT.PLATFORM] = 'Web'
    data[EVENT.APP_VERSION]=''
    data[EVENT.MSG_PUSH]=true
    data[EVENT.MSG_EMAIL]=true
    data[EVENT.MSG_SMS]=true
    data[EVENT.MSG_WHATSAPP] = true
   
    let child = LocalStorage.fetch.defaultChild()
    if(child){
        data[EVENT.NAME]=child.name
        data[EVENT.IDENTITY]=child.id
        data[EVENT.CT_CHILD_NAME]=child.name
        data[EVENT.CT_CHILD_ID]=child.id
        data[EVENT.CHILD_SCHOOL]=child.school
        data[EVENT.CHILD_GRADE]=child.grade.grade_name
        data[EVENT.CHILD_GRADE_ID]=child.grade.grade_id
        data[EVENT.CHILD_LEVEL]=child.gradeLevel
        data[EVENT.CHILD_PROFILE_IMAGE]=child.image
        data[EVENT.WOW_CODE]=child.invite_code
        data[EVENT.SUBSCRIPTION_STATUS]=child.subscription_status
        data[EVENT.ASSESSMENT_STATUS]=child.level_test_status
        data[EVENT.SURVEY_TAKEN]=child.commitment_taken
        data[EVENT.COUNTRY]=child.country?child.country:"India"
        data[EVENT.REGISTRATION_DATE]= child.created_at ? new Date(child.created_at) : undefined
        data[EVENT.CHILD_FIRST_ACTIVE]= child.created_at ? new Date(child.created_at) : undefined
        data[EVENT.SUBSCRIPTION_DATE] = child.subscription &&  child.subscription.subscription_start_date ? new Date(child.subscription.subscription_start_date) :undefined
        data[EVENT.TRIAL_DAYS]= child.trail_expired != null ? child.trail_expired ? child.trail_expired : 0 : undefined
    } 
    let parent = LocalStorage.fetch.parentData()
    if(parent){
        data[EVENT.PHONE] = `+${parent.country_code}${parent.contact_no}`
        data[EVENT.PARENT_ID]= parent.id
        data[EVENT.PARENT_NAME] = parent.name
        data[EVENT.PARENT_EMAIL]=parent.email
        //data[EVENT.EMAIL]=parent.email
        data[EVENT.PARENT_PHONE]=`${parent.country_code}${parent.contact_no}`
        data[EVENT.PARENT_FIRST_ACTIVE]=parent.created_at ? new Date(parent.created_at) : undefined
    }
    if (data) {
        console.log('logging profile',data)
        Object.keys(data).forEach(key => data[key] === undefined && delete data[key])
       window.clevertap.profile.push({"Site":data});
    } 
}
const onlogOut=()=>{
    window.clevertap.logout()
}
const onLogin = ()=>{
    let data ={}
    data[EVENT.PLATFORM] = 'Web'
    data[EVENT.APP_VERSION]=''
    data[EVENT.MSG_PUSH]=true
    data[EVENT.MSG_EMAIL]=true
    data[EVENT.MSG_SMS]=true
    data[EVENT.MSG_WHATSAPP] = true
    let child = LocalStorage.fetch.defaultChild()
    if(child){
        //FREAD-1301
        data[EVENT.NAME]=child.name
        data[EVENT.IDENTITY]=child.id
        data[EVENT.EMAIL]=child.email
        //Custom fields from Rohit's Log Profile
        data[EVENT.CT_CHILD_NAME]=child.name
        data[EVENT.CT_CHILD_ID]=child.id
        data[EVENT.CHILD_SCHOOL]=child.school
        data[EVENT.CHILD_GRADE]=child.grade.grade_name
        data[EVENT.CHILD_GRADE_ID]=child.grade.grade_id
        data[EVENT.CHILD_LEVEL]=child.gradeLevel
        data[EVENT.CHILD_PROFILE_IMAGE]=child.image
        data[EVENT.WOW_CODE]=child.invite_code
        data[EVENT.SUBSCRIPTION_STATUS]=child.subscription_status
        data[EVENT.ASSESSMENT_STATUS]=child.level_test_status
        data[EVENT.SURVEY_TAKEN]=child.commitment_taken
        data[EVENT.COUNTRY]=child.country?child.country:"India"
        data[EVENT.REGISTRATION_DATE]= child.created_at ? new Date(child.created_at) : undefined
        data[EVENT.CHILD_FIRST_ACTIVE]= child.created_at ? new Date(child.created_at) : undefined
        data[EVENT.SUBSCRIPTION_DATE] = child.subscription &&  child.subscription.subscription_start_date ? new Date(child.subscription.subscription_start_date) :undefined
        data[EVENT.TRIAL_DAYS]= child.trail_expired != null ? child.trail_expired ? child.trail_expired : 0 : undefined
        //Custom fields from Rohit's Log Profile
        //FREAD-1301
    } 
    let parent = LocalStorage.fetch.parentData()
    if(parent){
        //FREAD-1301
        data[EVENT.PHONE] = `+${parent.country_code}${parent.contact_no}`//parent.contact_no
       //data[EVENT.EMAIL]=parent.email
       data[EVENT.PARENT_EMAIL]=parent.email

        //Custom fields from Rohit's Log Profile
        data[EVENT.PARENT_ID]= parent.id
        data[EVENT.PARENT_NAME] = parent.name
        data[EVENT.PARENT_PHONE]=`${parent.country_code}${parent.contact_no}`
        data[EVENT.PARENT_FIRST_ACTIVE]=parent.created_at ? new Date(parent.created_at) : undefined
        //Custom fields from Rohit's Log Profile
        //FREAD-1301
    }
    
    if (data) {
        console.log('logging onUserLogin',data)
        Object.keys(data).forEach(key => data[key] === undefined && delete data[key])
        window.clevertap.onUserLogin.push({"Site":data});
    } 
}
const logNavigation=(data)=>{
    if(LocalStorage.fetch.authToken()){
        //FREAD-1301
       // logProfile();
        //FREAD-1301
    }
    let payload ={}
    payload[EVENT.FROM] =data.from // 
    payload[EVENT.TO] =data.to // 
    payload[EVENT.SESSION_ID]=LocalStorage.fetch.sessionData()?.session_data 
    payload[EVENT.TIMESTAMP]= new Date();
    payload[EVENT.CHILD_ID]=LocalStorage.fetch.defaultChild()?.id
    payload[EVENT.CHILD_LEVEL]=LocalStorage.fetch.defaultChild()?.grade_level
    if(data.collectionId){
        payload[EVENT.COLLECTION_ID]= data.collectionId
        payload[EVENT.COLLECTION_NAME]=data.collectionName
    }
    if (payload) {
        window.clevertap.event.push(EVENT.NAVIGATION_TO, payload);
    } else {
        window.clevertap.event.push(EVENT.NAVIGATION_TO);
    }
}
export const Analytics = {
    logNavigation,
    logEvents,
    logProfile,
    onLogin,
    onlogOut
  };