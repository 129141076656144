import React, { Component } from "react";
import "./Loader.scss";

class Loader extends Component {
  render() {
    return (
      <div className="component-loader">
        <div className="three-dots">
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
        </div>
      </div>
    );
  }
}

export default Loader;
