export const initialState = {
	userDetail: {},
	authToken: '',
	defaultChildData: {},
	childList: [],
	gradeList: [],
	selectedBatchId:'',
	//FLV-113
	navbarFlag: false
	//FLV-113
};
