export const initialState = {
    "address": "",
    "city": "",
    "father_details": {
        "employer_address": "",
        "employer_name": "",
        "name": "",
        "salary_bracket": ""
    },
    "locality": "",
    "mother_details": {
        "employer_address": "",
        "employer_name": "",
        "name": "",
        "salary_bracket": ""
    },
    "other_classes": [],
    "pincode": null,
    "program_expectations": "",
    "school_monthly_expense": null,
    "school_name": "",
    "state": "",
    name: "",
    preferenceGroupId: '',
    classDataLoaded: false,
    sessionInfo: {},
    classes: {
        upcoming: {
            class_sessions: []
        },
        previous: {
            class_sessions: []
        }
    },
    availableSchedules: {
        preference_groups: []
    }
};
