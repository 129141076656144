import { initialState } from "./state";

export const student = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PROFILE_INFO':
            let { details = {}, ...restDetails } = action?.data?.data || action?.data || {};
            state = { ...state, ...details, ...restDetails };
            return state;
        case "SET_AVAILABLE_SCHEDULE":
            return {
                ...state,
                availableSchedules: action.data.data
            }


        case 'SET_STUDENT_ID':
            return {
                ...state,
                studentID: action.data
            }

        case 'SET_STUDENT_BATCH':
            return {
                ...state,
                batchDetails: action.data
            }
            

        case 'SET_STUDENT_CLASSES':

            return ({
                ...state,
                classes: {
                    ...state.classes,
                    upcoming: action.data.upcoming && Object.keys(action.data.upcoming)?.length > 0 ? ({
                        ...state.classes?.upcoming,
                        ...action.data?.upcoming,
                        class_sessions: [
                            ...state.classes?.upcoming?.class_sessions,
                            ...action.data.upcoming?.class_sessions
                        ]
                    }) : state?.classes?.upcoming,
                    previous: action.data?.previous && Object.keys(action.data?.previous)?.length > 0 ? ({
                        ...state?.classes?.previous,
                        ...action.data?.previous,
                        class_sessions: [
                            ...state.classes?.previous?.class_sessions,
                            ...action.data?.previous?.class_sessions
                        ]
                    }) : state?.classes?.previous
                }
            })
        case 'LOADING_CLASS_DATA':
            return {
                ...state,
                classDataLoaded: false,
            }
        case 'CLASS_DATA_LOADED':
            return {
                ...state,
                classDataLoaded: true,
            }
        case "SET_SESSION_DATA":
            return {
                ...state,
                sessionInfo: {
                    ...state.sessionInfo,
                    [action.data.id]: action.data
                }
            }
        default:
            return state;
    }
};
