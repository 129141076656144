import {initialState} from "./state";

export const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SUBSCRIPTIONS_INFO':
      return {
        ...state,
        subscriptions: action.data.data,
      }
    default:
      return state;
  }
};
