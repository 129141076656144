export const initialState = {
  topStory: [],
  recommendedStory: [],
  activeStory: null,
  onGoingStories: [],
  likedStories: []
};


export const initialDetailedStoryState = {
  dataStory: {}
};  
