import { initialState } from "./state";

export const quizes = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QUIZ_LIST":
      return {
        ...state,
        quizList: action.data,
      };
    case "SET_ALL_QUIZ":
      return {
        ...state,
        allquiz: action.data,
      };
    case "SET_QUIZ_CONTENT":
      return {
        ...state,
        quizContent: action.data,
      };
      case "SET_QUIZ_BY_CATEGORY":
      return {
        ...state,
        quizCategory: action.data,
      };
    default:
      return state;
  }
};
