import { initialState } from "./state";

export const  linkschoolcode = (state = initialState, action) => {
  switch (action.type) {
      case "SET_SCHOOL_CODE_MODAL":
      return {
        ...state,
        schoolCodeModal: action.data,
      };
      case "SET_SCHOOL_CODE_DATA":
        return {
          ...state,
          ...action.data
        };
        case "SET_NEXT_GRADE":
          return {
            ...state,
            nextGrade : action.data
          };  
        case "SET_SCHOOL_CLASS_DATA":
          return {
            ...state,
            schoolSectionData : action.data
          }; 
        case "SET_LINK_SCHOOL_CODE_MODE":
          return {
            ...state,
            currentMode : action.data
          }; 
        case "SET_CMF_ATTEMPT":
          return {
            ...state,
            cmfAttempt : action.data
          }; 
    default:
      return state;
  }
};
