import { initialState } from "./state";

export const pricing = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRICING_PLAN": {
      return Object.assign({}, state, {
        plans: action.data
      });
    }
    case "SET_PROMO_DETAILS" : {
      return {
        ...state,
        promoDetails: action.data
      }
    }
    default:
    return state;
  }
};
