import { initialState } from "./state";

export const announcements = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ANNOUNCEMENT_LIST": {
      return {
        ...state,
        announcements: action.data.data
          .sort((a1, a2) => a1.date - a2.date)
          .reverse(),
      };
    }
    case "CLEAR_ANNOUNCEMENTS": {
      return {
        ...state,
        announcements: [],
      };
    }
    default:
      return state;
  }
};
