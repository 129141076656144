import {initialState} from "./state";

export const appConfig = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NAVBAR_VISIBILITY':
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
    }
};
