export const initialState = {
  passage: {},
  questionsList: [],
  has_next: false,
  already_attempt: false,
  next_page: 0,
  timerMinutes: 40,
  timerSeconds: 0,
  // state for Camera & Mic
  trackList: [],
};
